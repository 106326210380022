exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-plugin-dependencies-jsx": () => import("./../../../src/templates/plugin_dependencies.jsx" /* webpackChunkName: "component---src-templates-plugin-dependencies-jsx" */),
  "component---src-templates-plugin-documentation-jsx": () => import("./../../../src/templates/plugin_documentation.jsx" /* webpackChunkName: "component---src-templates-plugin-documentation-jsx" */),
  "component---src-templates-plugin-health-score-jsx": () => import("./../../../src/templates/plugin_healthScore.jsx" /* webpackChunkName: "component---src-templates-plugin-health-score-jsx" */),
  "component---src-templates-plugin-issues-jsx": () => import("./../../../src/templates/plugin_issues.jsx" /* webpackChunkName: "component---src-templates-plugin-issues-jsx" */),
  "component---src-templates-plugin-releases-jsx": () => import("./../../../src/templates/plugin_releases.jsx" /* webpackChunkName: "component---src-templates-plugin-releases-jsx" */),
  "component---src-templates-search-jsx": () => import("./../../../src/templates/search.jsx" /* webpackChunkName: "component---src-templates-search-jsx" */),
  "component---src-templates-tombstone-jsx": () => import("./../../../src/templates/tombstone.jsx" /* webpackChunkName: "component---src-templates-tombstone-jsx" */)
}

